<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div class="d-flex flex-column pa-4">
    <v-progress-linear class="my-3" v-if="loading" indeterminate />

    <v-row no-gutters>
      <!-- <v-col cols="12" sm="4" class="d-flex align-center flex-column pr-3">
        <v-card
          v-if="loading == false && photoId != ''"
          class="overflow-hidden my-4 py-4"
          max-width="100%"
          max-height="420"
          outlined
        >
          <v-img contain max-height="520" max-width="320" :src="photoId">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="primary" />
              </v-row>
            </template>
</v-img>
</v-card>
<v-card v-if="loading == false && photoId == ''" class="d-flex align-center justify-center" width="128" height="128"
  rounded="lg">
  <v-icon color="secondary" size="120"> mdi-account </v-icon>
</v-card>

<div class="d-flex flex-column fill-width mt-4">
  {{/* Upload foto */}}
  <v-card class="overflow-hidden rounded-lg" :loading="uploadingPatientPhoto || loading">
    <v-tabs v-model="uploadAs" grow>
      <v-tab>Upload a photo</v-tab>
      <v-tab>Take a photo</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-items v-model="uploadAs">
        <v-tab-item>
          <v-file-input v-model="patientPhoto" class="mt-3" label="Photo ID" accept=".jpg,.png,.jpeg" outlined dense
            @change="onFileUpload" />
        </v-tab-item>
        <v-tab-item>
          <camera-capture ref="camera" v-model="patientPhoto" />
        </v-tab-item>
      </v-tabs-items>

      <div class="d-flex justify-center m-3">
        <v-btn color="primary" :disabled="!havePhotoId" :loading="uploadingPatientPhoto" rounded text
          @click="uploadPatientPhoto">
          <v-icon left> mdi-cloud-upload </v-icon>
          Upload
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</div>
</v-col> -->

      <v-col>
        <template v-if="loading">
          <v-skeleton-loader transition="scale-transition" type="text" width="160" />
          <v-skeleton-loader transition="scale-transition" type="text" width="80" />
          <v-skeleton-loader transition="scale-transition" type="text" width="160" />
          <v-skeleton-loader transition="scale-transition" type="text" width="80" />
          <v-skeleton-loader transition="scale-transition" type="text" width="160" />
          <v-skeleton-loader transition="scale-transition" type="text" width="80" />
        </template>

        <template v-else>
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Full name
                </div>
                <div class="text-body-1">
                  {{ patientDetail.fullname ? patientDetail.fullname : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Email
                </div>
                <div class="text-body-1">
                  {{ patientDetail.email ? patientDetail.email : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Phone
                </div>
                <div class="text-body-1">
                  <template v-if="patientDetail.homePhone">
                    <div class="d-flex">
                      <div class="cursor-pointer" @click="makeCallV(patientDetail.homePhone)">
                        {{ patientDetail.homePhone }}
                      </div>
                      <div class="mx-1">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon @click="copyPhone(patientDetail.homePhone)" v-on="on" small
                              color="info">mdi-content-copy</v-icon>
                          </template>
                          <span>Copy Phone</span>
                        </v-tooltip>
                      </div>
                    </div>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Created by
                </div>
                <div class="text-body-1">
                  {{
                    patientDetail.createdBy
                      ? patientDetail.createdBy.fullname
                      : "-"
                  }}
                </div>
              </v-col>

            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  SSN
                </div>
                <div class="text-body-1">
                  {{ patientDetail.ssn ? "XXX-XX-" + patientDetail.ssn : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  DOB
                </div>
                <div class="text-body-1">
                  {{ birthDate }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Gender
                </div>
                <div class="text-body-1">
                  {{ patientDetail.isMale ? "Male" : "Female" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Source
                </div>
                <div class="text-body-1">
                  <template v-if="patientDetail">
                    <span v-bind:style="{ color: colorShowSource() }" class="font-weight-bold">
                      {{
                        patientDetail.sourceNom
                          ? patientDetail.sourceNom.name
                          : "-"
                      }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col>

            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Preferred Language
                </div>
                <div class="text-body-1 text-capitalize">
                  {{
                    patient.preferLanguage
                      ? patient.preferLanguage.toLowerCase()
                      : "-"
                  }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Contact preference
                </div>
                <div class="text-body-1 text-capitalize">
                  {{
                    patient.contactPreference
                      ? patient.contactPreference.toLowerCase()
                      : "-"
                  }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Number Id
                </div>
                <div class="text-body-1">
                  {{ patient.idNumber ? patient.idNumber : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Expiration Date
                </div>
                <div class="text-body-1">
                  {{ expirationDate }}
                </div>
              </v-col>

            </v-row>
            <v-divider class="my-2"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Married?
                </div>
                <div class="text-body-1">
                  {{ patientDetail.isMaried ? "Yes" : "No" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Legal Status
                </div>
                <div class="text-body-1">
                  {{ getLegal }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Status
                </div>
                <template v-if="loadingStatus">
                  <div>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                </template>
                <template v-else>
                  <v-menu bottom origin="center center" transition="scale-transition">
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="text-body-1 cursor-pointer" v-bind:style="{ color: colorShow() }">
                        {{ getStatusShow }}
                      </div>
                    </template>
                    <v-list>
                      <v-list-item class="cursor-pointer" v-for="(stat, index) in policyStates" :key="index"
                        @click="gotosetStatus(stat)" :disabled="patientDetail.polizeStatus == stat.value">
                        <v-list-item-title :class="patientDetail.polizeStatus == stat.value
                          ? 'status-active'
                          : ''
                          ">{{ stat.label }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <template v-if="patient.polizeStatus == 'POLICY_CANCELLATION'">
                    <div>
                      {{
                        patient.cancelledDate
                          ? getDateH(patient.cancelledDate)
                          : "-"
                      }}
                    </div>
                  </template>

                  <v-dialog v-model="setStatusObject.dialog" persistent :overlay="false" max-width="500px"
                    transition="dialog-transition">
                    <v-card>
                      <v-card-title class="primary white--text">
                        <template v-if="setStatusObject.value">
                          Status Change:
                          {{
                            getStatusShow + " to " + setStatusObject.value.label
                          }}
                        </template>
                      </v-card-title>
                      <v-card-text>
                        <v-textarea v-model.trim="setStatusObject.note" class="my-3" placeholder="Write a note"
                          :disabled="loading" outlined counter="240" />
                      </v-card-text>
                      <template v-if="!validNote">
                        <div class="mx-3">
                          <v-alert dense outlined type="error">
                            The note must be between 5 and 240 characters
                          </v-alert>
                        </div>
                      </template>
                      <v-card-actions class="d-flex justify-end">
                        <v-btn color="error" :disabled="setStatusObject.loading" text
                          @click="cancelSetStatus">cancel</v-btn>
                        <v-btn color="success" :loading="setStatusObject.loading"
                          :disabled="setStatusObject.loading || !validNote" text @click="setStatus">Change</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Tag
                </div>
                <div class="text-body-1">
                  <template v-if="patientDetail">
                    <span v-bind:style="{ color: colorShowTag() }" class="font-weight-bold">
                      {{
                        patientDetail.tagNom ? patientDetail.tagNom.name : "-"
                      }}
                    </span>
                  </template>
                  <template v-else> - </template>
                </div>
              </v-col>
            </v-row>
            <template v-if="patient.address && patient.address != {}">
              <v-divider class="my-2"></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1">...Loading States</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getState }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities">
                    <div class="text-body-1">...Loading Cities</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getCity }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1">
                    {{ patient.address ? patient.address.addressLine1 : "-" }}
                    {{
                      patient.address && patient.address.addressLine2
                        ? ", " + patient.address.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1">
                    {{ patient.address ? patient.address.zipCode : "-" }}
                  </div>
                </v-col>
              </v-row>
            </template>

            <template v-if="patient.mailAddress && patient.mailAddress != {}">
              <v-divider></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Mailing Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1">...Loading States</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getState1 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities1">
                    <div class="text-body-1">...Loading Cities</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getCity1 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.mailAddress
                        ? patient.mailAddress.addressLine1
                        : "-"
                    }}
                    {{
                      patient.mailAddress && patient.mailAddress.addressLine2
                        ? ", " + patient.mailAddress.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.mailAddress ? patient.mailAddress.zipCode : "-"
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-if="patient.billingAddress">
              <v-divider></v-divider>
              <div class="text-center font-weight-blod text-decoration-underline">
                Billing Address
              </div>
              <v-row class="mb-2" no-gutters>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    State
                  </div>
                  <template v-if="loadingStates">
                    <div class="text-body-1">...Loading States</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getState2 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    City
                  </div>
                  <template v-if="loadingCities2">
                    <div class="text-body-1">...Loading Cities</div>
                  </template>
                  <template v-else>
                    <div class="text-body-1">
                      {{ getCity2 }}
                    </div>
                  </template>
                </v-col>
                <v-col cols="12" sm="5">
                  <div class="text-caption font-weight-blod text--secondary">
                    Address
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.billingAddress
                        ? patient.billingAddress.addressLine1
                        : "-"
                    }}
                    {{
                      patient.billingAddress &&
                        patient.billingAddress.addressLine2
                        ? ", " + patient.billingAddress.addressLine2
                        : ""
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="2">
                  <div class="text-caption font-weight-blod text--secondary">
                    ZipCode
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.billingAddress
                        ? patient.billingAddress.zipCode
                        : "-"
                    }}
                  </div>
                </v-col>
              </v-row>
            </template>
            <v-divider class="my-2"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Employed Company
                </div>
                <div class="text-body-1">
                  {{ patient.employedCompany ? patient.employedCompany : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Work Phone
                </div>
                <div class="text-body-1">
                  {{ patient.workPhone ? patient.workPhone : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Occupation
                </div>
                <div class="text-body-1">
                  {{ patient.ocupation ? patient.ocupation : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Anual Income
                </div>
                <div class="text-body-1">
                  {{ patient.anualIncome ? patient.anualIncome : "-" }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row class="mb-2" no-gutters>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Quoted Date
                </div>
                <div class="text-body-1">
                  {{
                    patient.quotedDate ? formatDate(patient.quotedDate) : "-"
                  }}
                </div>
              </v-col>


              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Company
                </div>
                <div class="text-body-1">
                  {{ patient.company ? patient.company.name : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Company State
                </div>
                <div class="text-body-1">
                  {{ patient.companyState ? patient.companyState.name : "-" }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Binder pay
                </div>
                <div class="text-body-1 px-2">
                  {{ patient.binderDay ? "Yes" : "No" }}
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-2" />
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Metal Plan
                </div>
                <div class="text-body-1 font-weight-bold" v-bind:style="{ color: colorPolicy() }">
                  {{ patient.policyPlan ? patient.policyPlan : "-" }}
                </div>
              </v-col>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Premium
                </div>
                <div class="text-body-1">
                  {{ !patient.premium ? 0 : patient.premium | currency }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Policy Number
                </div>
                <div class="text-body-1 d-flex">
                  {{ patientDetail.polizeNumber ? patientDetail.polizeNumber : "-" }}
                  <div class="px-1">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon @click="copyPolizeNumber(patientDetail.polizeNumber)" v-on="on" small
                          color="info">mdi-content-copy</v-icon>
                      </template>
                      <span>Copy Policy Number</span>
                    </v-tooltip>
                  </div>
                </div>

              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Effective Date
                </div>
                <div class="text-body-1">
                  {{ efectiveDate }}
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-2" />
            <v-row no-gutters>

              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Members
                </div>
                <div class="text-body-1 px-4">
                  {{ patient.members ? patient.members : 0 }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  Total Members
                </div>
                <div class="text-body-1 px-4">
                  {{ patient.totalMembers ? patient.totalMembers : 0 }}
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="text-caption font-weight-blod text--secondary">
                  NPN
                </div>
                <div class="text-body-1">
                  {{ patient.npn ? patient.npn : '-' }}
                </div>
              </v-col>
            </v-row>

            <v-divider class="my-2" />
            <!--  <template v-if="patient.memberOf">
              <div class="font-weight-blod text-decoration-underline">
                Member of:
              </div>
              <v-row no-gutters>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">
                    Full Name
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.memberOf && patient.memberOf.fullname
                        ? patient.memberOf.fullname
                        : "-"
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">
                    Policy Number
                  </div>
                  <div class="text-body-1">
                    {{
                      patient.memberOf && patient.memberOf.polizeNumber
                        ? patient.memberOf.polizeNumber
                        : "-"
                    }}
                  </div>
                </v-col>
                <v-col cols="12" sm="3">
                  <div class="text-caption font-weight-blod text--secondary">
                    Details
                  </div>
                  <div class="text-body-1">
                    <v-btn
                      color="success"
                      icon
                      @click="gotToParent(patient.memberOf.uuid)"
                      ><v-icon>mdi-account</v-icon></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </template> -->
          </v-container>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import CameraCapture from "@/components/CameraCapture/CameraCapture.vue";

import { getAPI } from "@/api/axios-base";
import { notifyError, notifyInfo } from "@/components/Notification";
import { apiBaseUrl } from "@/enviorment";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { PolicyStatus } from "@/utils/enums/policystatus";
import { funtionsPhone, utilMixin } from "@/utils/mixins";

export default Vue.extend({
  components: { CameraCapture },
  name: "patient-personal-details",
  props: {
    patient: Object,
  },
  mixins: [utilMixin, funtionsPhone],
  data() {
    return {
      loading: true,
      photoId: "",
      patientPhoto: new Blob(),
      isAdmins: false,
      uploadAs: null,
      uploadingPatientPhoto: false,
      loadingCities: false,
      cities: [],
      loadingCities1: false,
      cities1: [],
      loadingCities2: false,
      cities2: [],
      loadingStatus: false,
      setStatusObject: {
        dialog: false,
        loading: false,
        value: null,
        note: "",
      },
    };
  },
  computed: {
    ...mapGetters(["isSuper", "isAdmin", "isSupervisor"]),
    ...mapState("crmMedicFormModule", [
      "patientDetail",
      "states",
      "loadingStates",
    ]),

    validNote() {
      if (
        this.setStatusObject.note == "" ||
        this.setStatusObject.note == null ||
        this.setStatusObject.note == undefined
      ) {
        return false;
      }
      if (
        this.setStatusObject.note.length < 5 ||
        this.setStatusObject.note.length > 240
      ) {
        return false;
      }
      return true;
    },
    policyStates() {
      const states = this.isAdmins
        ? [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "PAYMENT_PENDING", label: "Payment Pending" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ]
        : [
            { value: "POLICY_NEW", label: "Policy New" },
            { value: "POLIZE_PENDING", label: "Policy Pending" },
            { value: "POLIZE_OK", label: "Policy Ok" },
            { value: "PAYMENT_OK", label: "Payment Ok" },
            { value: "POLICY_CANCELLATION", label: "Cancelled" },
            { value: "POLICY_RENEWAL", label: "Renewal" },
            { value: "POLICY_RECONCILIATION", label: "Reconciliation" },
            { value: "OTHER_PARTY", label: "Other Party" },
            { value: "NO_AOR", label: "No Aor" },
            { value: "NO_AOR_ACTIVE", label: "No Aor Active" },
            { value: "POLICY_BLACKLIST", label: "Blacklist" },
            { value: "CHARGE_BACK", label: "Charge Back" },
            { value: "PENDING_RENEWAL", label: "Pending Renewal" },
            { value: "NO_RENEWAL", label: "No Renewal" },
            { value: "PROSPECT_RENEWAL", label: "Prospect Renewal" },
          ];
      return states;
    },
    birthDate() {
      if (!this.patientDetail.birthDate) {
        return "-";
      }
      const date = moment(this.patientDetail.birthDate);

      return date.format("MM/DD/YYYY");
    },
    getState() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.address == null ||
        this.patientDetail.address == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.address.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getState1() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.mailAddress == null ||
        this.patientDetail.mailAddress == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.mailAddress.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getState2() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.billingAddress == null ||
        this.patientDetail.billingAddress == undefined
      ) {
        return "-";
      }
      if (this.states.length == 0) {
        return "-";
      }
      const re = this.states.find(
        (r) => r.id == Number(this.patientDetail.billingAddress.state)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.address == null ||
        this.patientDetail.address == undefined
      ) {
        return "-";
      }
      if (this.cities.length == 0) {
        return "-";
      }
      const re = this.cities.find(
        (r) => r.id == Number(this.patientDetail.address.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity1() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.mailAddress == null ||
        this.patientDetail.mailAddress == undefined
      ) {
        return "-";
      }
      if (this.cities1.length == 0) {
        return "-";
      }
      const re = this.cities1.find(
        (r) => r.id == Number(this.patientDetail.mailAddress.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },
    getCity2() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      if (
        this.patientDetail.billingAddress == null ||
        this.patientDetail.billingAddress == undefined
      ) {
        return "-";
      }
      if (this.cities2.length == 0) {
        return "-";
      }
      const re = this.cities2.find(
        (r) => r.id == Number(this.patientDetail.billingAddress.city)
      );
      if (re) {
        return re.name;
      }
      return "-";
    },

    expirationDate() {
      if (!this.patientDetail.expDate) {
        return "-";
      }
      const date = this.patientDetail.expDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];

      return `${m}/${d}/${y}`;
    },
    efectiveDate() {
      if (!this.patientDetail.effectiveDate) {
        return "-";
      }

      const date = this.patientDetail.effectiveDate.split("T")[0];
      const y = date.split("-")[0];
      const m = date.split("-")[1];
      const d = date.split("-")[2];

      return `${m}/${d}/${y}`;
    },

    getStatusShow() {
      if (this.patientDetail == null || this.patientDetail == undefined) {
        return "-";
      }
      const p = this.patientDetail.polizeStatus;
      if (p) {
        const st = PolicyStatus[p];
        return st;
      }

      return "-";
    },

    havePhotoId() {
      const havePhotoId =
        (this.patientPhoto instanceof Blob && this.patientPhoto.size > 0) ||
        (this.patientPhoto instanceof File && this.patientPhoto.size > 0);

      return havePhotoId;
    },
    bmi() {
      if (this.patientDetail.weight == 0 && this.patientDetail.height == 0)
        return 0;
      const inchtometer = this.patientDetail.heightInches * 0.0254;
      const feettometer = this.patientDetail.height * 0.3048;
      const bmi =
        (Number(this.patientDetail.weight) * 0.453592) /
        Math.pow(feettometer + inchtometer, 2);
      return Number(bmi).toFixed(2);
    },

    getLegal() {
      switch (this.patientDetail.legalStatus) {
        case "CITIZEN":
          return "Citizen";
        case "PERMANENT_RESIDENT":
          return "Permanent Resident";
        case "TEMPORAL_RESIDENT":
          return "Temporal Resident";

        default:
          return "-";
      }
    },
    typebmi() {
      const bmitemp = Number(this.bmi);

      if (bmitemp == 0) {
        return "No BMI";
      }
      if (bmitemp <= 18.5) {
        return "Underweight";
      }
      if (bmitemp > 18.5 && bmitemp <= 24.9) {
        return "Normal weight";
      }
      if (bmitemp >= 25 && bmitemp <= 29.9) {
        return "Overweight";
      }

      return "Obesity";
    },
    colorWeight() {
      if (this.typebmi == "No BMI") {
        return "grey";
      }
      if (this.typebmi == "Underweight") {
        return "amber";
      }
      if (this.typebmi == "Normal weight") {
        return "green";
      }
      if (this.typebmi == "Overweight") {
        return "orange";
      }
      if (this.typebmi == "Obesity") {
        return "red";
      }
    },
  },
  watch: {
    procedure() {
      this.getPhoto();
    },
  },
  mounted() {
    if (this.isSuper || this.isAdmin || this.isSupervisor) {
      this.isAdmins = true;
    }
    this.getPhoto();

    this.actListStates();
    this.getCities();
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutPatientDetails"]),

    ...mapActions("crmMedicFormModule", [
      "actListStates",
      "actGetCityByState",
      "getPhotoId",
    ]),

    getCities() {
      if (
        this.patientDetail &&
        this.patientDetail.address &&
        this.patientDetail.address.state
      ) {
        this.loadingCities = true;
        this.actGetCityByState(Number(this.patientDetail.address.state)).then(
          (cities) => {
            this.loadingCities = false;
            this.cities = cities;
          }
        );
      }
      if (
        this.patientDetail &&
        this.patientDetail.mailAddress &&
        this.patientDetail.mailAddress.state
      ) {
        this.loadingCities1 = true;
        const st =
          Number(this.patientDetail.mailAddress.state) != 0
            ? Number(this.patientDetail.mailAddress.state)
            : 23;
        this.actGetCityByState(st).then((cities) => {
          this.loadingCities1 = false;
          this.cities1 = cities;
        });
      }
      if (
        this.patientDetail &&
        this.patientDetail.billingAddress &&
        this.patientDetail.billingAddress.state
      ) {
        this.loadingCities2 = true;
        this.actGetCityByState(
          Number(this.patientDetail.billingAddress.state)
        ).then((cities) => {
          this.loadingCities2 = false;
          this.cities2 = cities;
        });
      }
    },
    colorPolicy() {
      const status = this.patientDetail.policyPlan;
      if (status != null && status != undefined) {
        switch (status) {
          case "GOLD":
            return "#EDC43D";
          case "SILVER":
            return "#C0C0C0";
          case "BRONZE":
            return "#CD7F32";

          default:
            return "#000";
        }
      }
      return "#000";
    },
    colorShow() {
      const status = this.patientDetail.polizeStatus;
      if (status != null && status != undefined) {
        switch (status) {
          case "POLIZE_PENDING":
            return "#1663E0FF";
          case "PAYMENT_PENDING":
            return "#4B7ECE";
          case "POLIZE_OK":
            return "#19FF00FF";
          case "POLICY_NEW":
            return "#80FF00";
          case "PAYMENT_OK":
            return "#00FFB3";
          case "POLIZE_MEMBERS_NO_MATCH":
            return "#FF0033";
          case "PAYMENT_MEMBERS_NO_MATCH":
            return "#FFAA00";
          case "POLICY_CANCELLATION":
            return "#FF4400";
          case "DELINCUENT":
            return "#DE7954";
          case "POLICY_RECONCILIATION":
            return "#B7FF00";
          case "POLICY_RENEWAL":
            return "#EEC167";
          case "OTHER_PARTY":
            return "#9C27B0";
          case "NO_AOR":
            return "#625F62";
          case "NO_AOR_ACTIVE":
            return "#8e3bed";
          case "CHARGE_BACK":
            return "#9366C6";
          case "POLICY_BLACKLIST":
            return "#270E41";
          default:
            return "#000";
        }
      }
      return "#000";
    },

    colorShowTag() {
      if (this.patientDetail && this.patientDetail.tagNom) {
        return this.patientDetail.tagNom.color;
      }
      return "#000";
    },
    gotToParent(uuid) {
      this.loading = true;
      getAPI(`/patient/getPatient/${uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
        this.loading = false;
        this.$emit("parent");
        this.$router.push(`/clients/details/${uuid}`);
      });
    },
    getPatient() {
      getAPI(`/patient/getPatient/${this.$route.params.uuid}`).then((res) => {
        this.mutPatientDetails(res.data);
      });
    },
    getPhone(phone) {
      if (phone[0] == "+") {
        return phone;
      }
      const pho = "+1" + phone;
      return pho;
    },

    colorShowSource() {
      if (this.patientDetail && this.patientDetail.sourceNom) {
        return this.patientDetail.sourceNom.color;
      }
      return "#000";
    },
    nameShowSource() {
      return this.patientDetail && this.patientDetail.sourceNom
        ? this.lead.sourceNom.name
        : "-";
    },

    formatDate(date) {
      const d = moment(date).format("MM/DD/YYYY");
      return d;
    },

    async getPhoto() {
      this.loading = true;
      const patientdata = (
        await getAPI(`/patient/getPatient/${this.$route.params.uuid}`)
      ).data;

      const token = this.$store.state.accessToken;
      const auth =
        token != undefined && token != null && token != ""
          ? {
              Accept: "image/jpg",
              Authorization: `Bearer ${token}`,
            }
          : {
              Accept: "image/jpg",
            };

      if (patientdata.avatar != null) {
        axios({
          method: "get",
          url: `${apiBaseUrl}/patient/downloadAvatar/${this.patientDetail.uuid}`,
          responseType: "blob",
          headers: auth,
        })
          .then((response) => {
            const blob = new Blob([response.data]);
            let reader = new FileReader();

            reader.onloadend = () => {
              let base64data = reader.result;

              this.photoId = base64data;
            };
            reader.readAsDataURL(blob);
            this.loading = false;
          })
          .catch(() => {
            this.patientPhoto = new Blob();
            this.loading = false;
            // notificationError();
          });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    onFileUpload(file) {
      if (file == null) {
        return;
      }
      const { name } = file;

      if (
        !name.includes(".png") &&
        !name.includes(".jpg") &&
        !name.includes(".jpeg")
      ) {
        notifyError(
          "The image format is not allowed, select <b>.png</b>, <b>.jpg</b> or <b>.jpeg</b>"
        );
        this.patientPhoto = new Blob();
      }
    },
    async uploadPatientPhoto() {
      const formDataPhotoId = new FormData();

      this.uploadingPatientPhoto = true;
      formDataPhotoId.append(
        "photo",
        this.patientPhoto,
        `photo-id-${this.patientDetail.uuid}`
      );

      await getAPI({
        method: "post",
        url: `/patient/uploadPatientAvatar/${this.patientDetail.uuid}`,
        data: formDataPhotoId,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(() => {
          if (this.$refs.camera) {
            this.$refs.camera.closeCamera();
          }
          this.uploadingPatientPhoto = false;
          this.getPhoto();
        })
        .catch((error) => {
          this.uploadingPatientPhoto = false;

          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    makeCallV(item) {
      getAPI("/zoom/zoomPhonecall", {
        params: {
          phone: item,
        },
      }).then((res) => {
        window.open(res.data, "_blank", "noreferrer");
      });
    },
    gotosetStatus(stat) {
      this.setStatusObject = {
        dialog: true,
        value: stat,
        loading: false,
        note: "",
      };
    },
    cancelSetStatus() {
      this.setStatusObject = {
        dialog: false,
        value: null,
        loading: false,
        note: "",
      };
    },
    setStatus() {
      this.setStatusObject.loading = true;
      getAPI
        .put(`/patient/updatePatient/${this.patientDetail.uuid}`, {
          polizeStatus: this.setStatusObject.value.value,
          noteForStatusChange: this.setStatusObject.note,
        })
        .then(() => {
          this.setStatusObject.loading = false;
          notifyInfo(this.$t("Status has been updated"));

          this.cancelSetStatus();
          this.getPatient();
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          this.setStatusObject.loading = false;
          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
</script>
<style lang="scss" scoped>
.status-active {
  color: rgb(231, 49, 49) !important;
}
</style>
