































































































/* eslint-disable @typescript-eslint/no-explicit-any */
// Adapted from: https://codepen.io/ditarahma08/pen/GRRxZLW
/**
 * The component is only to output values not input:
 * <camera-capture v-model="fooBar" />
 */
import Vue from "vue";
import { notifyError } from "../Notification";
import "./CameraCapture.scss";

export default Vue.extend({
  name: "camera-capture",
  props: {
    value: Blob,
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
    };
  },

  methods: {
    toggleCamera() {
      if (this.isCameraOpen) {
        this.closeCamera();
        this.$emit("input", new Blob());
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = ((window as any).constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          (this as any).$refs.camera.srcObject = stream;
        })
        .catch(() => {
          this.isLoading = false;
          this.toggleCamera();
          notifyError(
            `<div class="text-h6">Ups!, there is a problem</div>
            <ul>
              <li class="text-body-1">Check that your browser have support for webcam.</li>
              <li class="text-body-1">The browser must have permission to webcam.</li>
            </ul>`
          );
        });
    },
    stopCameraStream() {
      const stream = (this as any).$refs.camera.srcObject;

      if (stream != null || stream != undefined) {
        let tracks = (this as any).$refs.camera.srcObject.getTracks();

        tracks.forEach((track: any) => {
          track.stop();
        });
      }
    },
    takePhoto() {
      const canvas = (this as any).$refs.canvas;

      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 200;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context: CanvasRenderingContext2D = canvas.getContext("2d");
      context.drawImage((this as any).$refs.camera, 0, 0, 450, 337.5);
      canvas.toBlob((blob: Blob) => {
        this.$emit("input", blob);
      });
    },
    closeCamera() {
      this.isCameraOpen = false;
      this.isPhotoTaken = false;
      this.isShotPhoto = false;
      this.stopCameraStream();
      this.$emit("input", new Blob());
    },

    // downloadImage() {
    //   const download = document.getElementById("downloadPhoto");
    //   const canvas = (document as any)
    //     .getElementById("photoTaken")
    //     .toDataURL("image/png")
    //     .replace("image/png", "image/octet-stream");
    //   (download as HTMLLinkElement).setAttribute("href", canvas);
    // },
  },
});
